import React from 'react';
import DataTable from 'react-data-table-component';
 
// const data = [{ id: 1, title: 'Conan the Barbarian', year: '1982' }];
// const columns = [
//   {
//     name: 'Title',
//     selector: 'title',
//     sortable: true,
//   },
//   {
//     name: 'Year',
//     selector: 'year',
//     sortable: true,
//     right: true,
//   },
// ];
export const Table=({titulo,columns,data})=>{
  
  return (
    <div className="card card-custom  col-12">      
      <div >
        <DataTable          
          title={titulo}
          columns={columns}
          data={data}
          highlightOnHover={true}
          pointerOnHover={true}
          pagination={true}
          subHeader={true}
          // selectableRows={true}
          persistTableHead={true}          
         />
      </div>      
    </div>    
  )
}

