export const getFullLista=async()=>{
  const url='https://humanly-sw.com/api-administrar-sistemas/Data/ListadoDeServicios';
  const resp=await fetch(url);
  const json=await resp.json(); 
  return json
}
/**
 * 
 * 
 * export const getFullLista=async()=>{
  const url='https://humanly-sw.com/api-administrar-sistemas/Data/ListadoDeServicios';
  const resp=await fetch(url);
  const json=await resp.json(); 
  return json
}
 */