
export const inserPagoVerificado=async({fecha,id_pagos,id_lista_servicio})=>{
  try {    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("fecha", fecha);
    urlencoded.append("id_pagos", id_pagos);
    urlencoded.append("id_lista_servicio",id_lista_servicio);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    let url="https://humanly-sw.com/api-administrar-sistemas/PagosVerificados";
    const resp = await fetch(url, requestOptions);
    const json = await resp.json();
    return json;
  } catch (error) {
    return 0;
  }
}

