import Swal from "sweetalert2"


export const useFetchNotificacion = () => {
  const toas =(mensaje,icono='success')=>{
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
    Toast.fire({
      icon:icono,
      title: mensaje
    })
  }
  return {
    toas
  }
}
