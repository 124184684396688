import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({  
  inline: {
    display: 'inline',
  },
}));

export const ListaItem = ({producto,fecha_inicio_demo,fecha_fin_demo,dia_factura}) => {
  const classes = useStyles();

  return (
    <>
      <ListItem alignItems="flex-start" className='animate__animated animate__zoomIn'>
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="https://img.icons8.com/fluent/48/000000/service.png" />
        </ListItemAvatar>
        <ListItemText
          primary={producto}
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Los dias {dia_factura} se debe mandar factura al cliente
              </Typography>
              {` - Fecha de inició demo ${fecha_inicio_demo} fecha fin demo ${fecha_fin_demo} `}
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />      
    </>
  );
}
