import {useEffect,useState} from 'react';
import {getHistorial} from '../helpers/getHistorial';
import {getFullLista} from '../helpers/getFullLista';
import {getDataGrafica} from '../helpers/getDataGrafica';
import {serviciosByClientes} from '../helpers/serviciosByClientes';

export const useFetchHistorial = () => {
  const [listaHistorial, setListaHistorial] = useState([]);
  const [listaGeneral, setListaGeneral] = useState([])
  const [clientes, setClientes] = useState([]);
  const [numProductos, setNumProductos] = useState([]);
  const [ContenidoServicio, setContenidoServicio] = useState([]);

  useEffect(() => {
    get_lista_historial();
    get_lista_general();
    get_dataGrafica();
  }, []);

  const get_dataGrafica = () => {
    getDataGrafica()
      .then((json) => {
        let clientestem = [];
        let totales = [];
        json.map(({
          cliente,
          total
        }) => {
          clientestem.push(cliente);
          totales.push(total);
          return 1;
        });
        setClientes(clientestem);
        setNumProductos(totales);
      })
  }

  const get_lista_historial = () => {
    getHistorial()
      .then(respuesta => {
        setListaHistorial(respuesta)
      });
  }
  const get_lista_general = () => {
    getFullLista()
      .then(respuesta => {        
        setListaGeneral(respuesta);
      })
  }

  const get_productos = (id_cliente) => {
    serviciosByClientes(id_cliente).then(respuesta => {
      setContenidoServicio(respuesta);
    });
  }

  return {
    listaHistorial,
    listaGeneral,
    clientes,
    numProductos,
    get_productos,
    ContenidoServicio
  }
}