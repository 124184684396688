import React,{useState,useEffect} from 'react';
import {reactLocalStorage} from 'reactjs-localstorage';
import {HeadMovil} from './../components/HeadMovil';
import {HeadG} from './../components/HeadG';
import {Foother} from './../components/Foother';
import {useFetchCliente} from './../hooks/useFetchCliente';
import { Table } from './Table';
import { Redirect } from 'react-router-dom';
import {useFetchNotificacion} from './../hooks/useFetchNotificacion';

export const Cliente=()=>{
  const [cliente , setcliente] = useState({'nombre':''});
  const  {privilegio}=reactLocalStorage.getObject('session');
  const {state:{data},Inserted_message,NuevoCliente}= useFetchCliente();
  const {toas}=useFetchNotificacion();

  useEffect(() => {
    if (privilegio===undefined) {
      window.location.href='https://recursos.humanly-sw.com';  
    }        
  });

  useEffect(() => {
 
    if(Inserted_message.code===400){
      toas(Inserted_message.msj,'error');
    }else{
      if ( Inserted_message.code===200&&Inserted_message.msj==='success') {
        setcliente({'nombre':''});
        toas('Cliente creado correctamente');
      }
    }
    // eslint-disable-next-line
  }, [Inserted_message]);

  const handleChange=({target})=> {
    let {name,value}=target;    
    setcliente(
      {[name]:value}
    );
  }

  const columns = [{
    name: 'Nombre',
    selector: 'nombre',
    sortable: true,
  }];

  const nuevo = e =>{     
    e.preventDefault();  
    NuevoCliente(cliente);  
    toas('creando usuario','warning');  
  }
  return(
  <>
    {privilegio===undefined&&<Redirect to='./'/>}
    <HeadMovil />
      <div className="d-flex flex-column flex-root">	
        <div className="d-flex flex-row flex-column-fluid page">	
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">				
            <HeadG/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">	
              <div className="container">                
                <div className="row">
                  {/* dentro de este div va montado todo */}
                  <div className="card card-custom col-12 col-md-6 mt-5">
                    <div className="card-header">                      
                      <h3 className="card-title">Registro de clientes  </h3>
                    </div>
                    <form className="form" onSubmit={nuevo}>
                      <div className="card-body">
                      <div className="form-group">
                        <label>Nombre o razón social del cliente
                        <span className="text-danger">*</span></label>
                        <input type="text" 
                          name='nombre' 
                          value={cliente.nombre}
                          onChange={handleChange} 
                          class="form-control" 
                          placeholder="Escribir" />
                      </div>
                      </div>
                      <div className="card-footer">
                        <button type='submit' className="btn btn-primary mr-2">Guardar cliente</button>          
                      </div>
                    </form>          
                  </div>      

                  <div className="card card-custom col-12 col-md-5 ml-2 mr-2 mt-5">
                    <Table data={data} columns={columns} titulo='Lista de clientes' />
                  </div>              
                  
                  {/* fin de todo el body del template */}
                </div>				
              </div>				                     					                
            </div>
            <Foother/>
          </div>
        </div>
    </div>      
  </>
)
}