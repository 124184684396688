import { useEffect, useState } from "react";
import axios from 'axios';
import {getCotizacion} from './../helpers/getCotizacion';

export const useFetchFactura = () => {
  const [Inserted_message, setInserted_message] = useState({"data":[],"code":200,"msj":""});
  const [Update_message, setUpdate_message] = useState({"data":[],"code":200,"msj":""});
  const [productos, setproductos] = useState([]);
  const [Cliente, setCliente] = useState(0)

  useEffect(() => {    
    GetFactura(Cliente);    
  }, [Cliente])
  
  const  GuardarFactura =  (producto,nuevo=true) => {        
    let url=''
    if (nuevo) {
      url='https://humanly-sw.com/api-administrar-sistemas/Service';
    }else{
      url='https://humanly-sw.com/api-administrar-sistemas/Data/Nuevo_servicio';
    }

    axios.post(url,
      producto
    )
    .then((respuesta)=>{      
      setInserted_message(respuesta.data);      
    })
    .catch(err=>{
      console.error(err);
    })
  }

  const UpdateFactura=(producto)=>{
    axios.post('https://humanly-sw.com/api-administrar-sistemas/Data/updatedService',
    producto)
    .then((respuesta)=>{
      setUpdate_message(respuesta.data);
    })
    .catch(err=>{
      console.error(err);
    })
  }
  
  const GetFactura= (cliente)=>{
    getCotizacion(cliente)          
    .then(p => {
      setproductos(p);
    });
  }
  return {
    GuardarFactura,
    Inserted_message,
    productos,
    setproductos,
    GetFactura,
    UpdateFactura,
    Update_message,setCliente
  };
}