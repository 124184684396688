export const insertGasto = async ({nombre,dia_pago}) => {
  try {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    let urlencoded = new URLSearchParams();
    urlencoded.append("nombre", nombre);
    urlencoded.append("dia_pago", dia_pago);

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    let url="https://humanly-sw.com/api-administrar-sistemas/Pagos";
    const resp = await fetch(url, requestOptions);
    const json = await resp.json();
    return json;
  
  } catch (error) {
    return 0;
  }
}

  