export const getCotizacion=async(id)=>{
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  let urlencoded = new URLSearchParams();
  urlencoded.append("id",id);  

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };

  const url=`https://humanly-sw.com/api-administrar-sistemas/Data/Get_cotizacion`;
  const resp = await fetch(url, requestOptions);
  const json = await resp.json();
  return json;  
}

/*


export const getCotizacion=async()=>{
  const url='https://humanly-sw.com/api-administrar-sistemas/Cliente';
  const resp=await fetch(url);
  const json=await resp.json();
  return json;
}
*/