import React from 'react'
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import { Factura } from './components/Factura';
import { Servicios } from './components/Servicios';
import { Cliente } from './components/Cliente';
import { DetalleFactura } from './components/DetalleFactura';
//import { Historial } from './components/Historial';
import { Home } from './components/Home';
import { Error404 } from './components/Error404/Error404';
import { Login } from './components/Login/Login';
import { Usuarios } from './components/Usuarios';
import {Gastos} from './components/Gastos';
import { PagoFactura } from './components/pagos/PagoFactura';
// import { Redimenciona } from './components/redimenciona';

export const Routers=()=> {
  return (
    //
    <Router>
      <Switch>
        <Route exact path='/'>
          <Login/>
        </Route>
        <Route exact path='/login'>
          <Login/>
        </Route>

        <Route exact path='/home'>
          <Home/>
        </Route>

        <Route exact path='/servicios'>
          <Servicios/>
        </Route>

        <Route exact path='/clientes'>
          <Cliente/>
        </Route>

        <Route exact path='/cotizacion'>
          <Factura/>
        </Route>

        <Route exact path='/cotizacion/ver'>
          <DetalleFactura/>
        </Route>

        
        <Route exact path='/admin'>
          <PagoFactura />
        </Route>

        <Route exact path='/usser'>
          <Usuarios />
        </Route>

        <Route exact path='/pagos'>
          <Gastos/>
        </Route>

        <Route exact path='/prueba'>
          <PagoFactura/>
        </Route>
    
        
        <Route >
          <Error404 />
        </Route>
      </Switch>
    </Router>   
  )
}
