import { useState, useEffect } from "react";
import { insertGasto } from "./../helpers/insertGasto";
import { getGastos } from "./../helpers/getGastos";
import { updateGasto } from "../helpers/updateGasto";
import { deletedGasto } from "./../helpers/deletedGasto";
import { selectPasgosVerificados } from "../helpers/selectPasgosVerificados";
import { inserPagoVerificado } from "../helpers/inserPagoVerificado";
import { activaServicio } from "../helpers/Updates/activaServicio";

export const useFetchGastos = () => {
  const [insertedGasto, setInsertedGasto] = useState({ code: 0, msj: "" });
  const [insertedPago, setInsertedPago] = useState({ code: 0, msj: "" });
  const [ListaGastos, setListaGastos] = useState({});
  const [UpdatedGasto, setUpdatedGasto] = useState({ code: 0, msj: "" });
  const [DeletedMessage, setDeletedMessage] = useState({ code: 0, msj: "" });
  const [ListaGastosVerificados, setListaGastosVerificados ] = useState([{}]);
  const [StatusActivar, setStatusActivar] = useState({ code: 0, msj: "" });
  useEffect(() => {
    getGastos().then((respuesta) => {
      setListaGastos(respuesta);
    });
    traerGastosVerificados();
    // eslint-disable-next-line
  }, []);

  const InsertarGastos = (gasto) => {
    // eslint-disable-next-line
    insertGasto(gasto).then((repuesta) => {
      setInsertedGasto(repuesta);
      traerGastos();
    });
  };

  const traerGastos = () => {
    getGastos().then((respuesta) => {
      setListaGastos(respuesta);
    });
  };

  const updategasto = (obj) => {
    // eslint-disable-next-line
    updateGasto(obj).then((respuesta) => {
      setUpdatedGasto(respuesta);
      traerGastos();
    });
  };

  const EliminaGasto = (id_pagos) => {
    deletedGasto(id_pagos).then(respuesta=>{      
      setDeletedMessage(respuesta);
      traerGastos();
    });
  };

  const traerGastosVerificados=()=>{
    selectPasgosVerificados().then(respuesta=>{
      setListaGastosVerificados(respuesta.data);
    })
  }
  
  const Pagar=(pago)=>{
    inserPagoVerificado(pago)
    .then(respuesta=>{
      setInsertedPago(respuesta);
      traerGastosVerificados();
    });    
  }

  const Activar=(id)=>{
    activaServicio(id)
    .then(respuesta=>{
      setStatusActivar(respuesta);
    })
  }

  return {
    insertedGasto,
    InsertarGastos,
    ListaGastos,
    UpdatedGasto,
    updategasto,
    DeletedMessage,
    EliminaGasto,
    ListaGastosVerificados,    
    Pagar,
    insertedPago,
    traerGastosVerificados,
    Activar,
    StatusActivar
  };
};
