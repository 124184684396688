import React,{useEffect,useState} from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import  'moment/locale/es';
import { Button } from '@material-ui/core';
import { useFetchGastos } from '../../hooks/useFetchGastos';

import Swal from 'sweetalert2';
import { useFetchNotificacion } from '../../hooks/useFetchNotificacion';


export const TablePagos = ({lista={}}) => {
  
  moment.locale('es');
  const {ListaGastosVerificados,Pagar,insertedPago}= useFetchGastos();
  const {toas}=useFetchNotificacion();
  // eslint-disable-next-line
  const [data, setData] = useState([{}]);
  const [Listado, setListado] = useState([{}]);
  
  useEffect(() => {
    let x=0;
    while (x<=2) {
      cargar();
      x++;
    }
    // eslint-disable-next-line
  }, [lista,ListaGastosVerificados]);

  const cargar=()=>{
    if (lista?.data) {
      let {data}=lista;
      let List=[];
      // eslint-disable-next-line
      data.map(({dia_pago,id_pagos,nombre})=>{
        let fecha_pago=Crea_primera_fecha(dia_pago);        
        let fecha_final=fechaCancelacion(fecha_pago);     
        let hoy = moment().format(`yyyy-MM-DD`);
        let periodo=Periodo(hoy,fecha_pago);
        let estado=Estatus(periodo);
        let sumaMes=moment(fecha_pago,`yyyy-MM-DD`);
        sumaMes=sumaMes.add(1,'M').format(`yyyy-MM-DD`);
        let prueba=ListaGastosVerificados.find(pago=>pago.id_pagos===id_pagos&&pago.fecha===sumaMes);        
        if(prueba?.fecha){
          estado='Pagado';
          periodo=Periodo(hoy,prueba.fecha);          
        }        
        List.push({dia_pago,id_pagos,nombre,fecha_pago,fecha_final,hoy,periodo,estado});        
      });      
      setData(List);   
      setListado(List);
    }
  }

  useEffect(() => {
    const {code,msj}=insertedPago;
    if(code===200&&msj==='success'){
      toas('Pago creado correctamente');
    }else{
      if(code===400){
        toas(msj,'warning');
      }
    }  
    // eslint-disable-next-line  
  }, [insertedPago])

  const Crea_primera_fecha=(dias)=>{
    let fecha = moment().format(`yyyy-MM-[${dias}]`);
    let nueva=moment(fecha,'yyyy-MM-DD');
    nueva.add(1,'M').format('LLL');    
    return fecha;
  }

  const fechaCancelacion=(fecha='')=>{
    let mm = moment(fecha, "yyyy-MM-DD");
    mm.add(1, "M").format("LLL");
    let fechaFinal = mm.toString();
    let demo_fin = moment(fechaFinal).format("yyyy-MM-DD");    
    return demo_fin;
  }

  const Periodo=(fehca_inicio='',fecha_fin='')=>{
    let fecha1 = moment(fehca_inicio);
    let fecha2 = moment(fecha_fin);
    
    let dias=fecha2.diff(fecha1, 'days');
    return dias;
  }
  const Estatus=(dias,pagado=false)=>{
    if (pagado) {

    }else{
      if (dias===0) {
        return 'Hoy es el ultimo dia para pagar';
      }else{
        if (dias>0&&dias<=5) {
          return `Tienes ${dias} para pagar`;
        }else{
          return `Vencido`;
        }
      }
    }
    
  }

  
  const Guardar=({id_pagos,nombre,fecha_pago})=>{    
    let fecha = moment(fecha_pago,'yyyy-MM-DD');
    fecha=fecha.add(1,'M').format('yyyy-MM-DD');
    fecha_pago =fecha;
    Swal.fire({
      title: `Gasto de ${nombre} a pagar`,
      input: 'text',
      inputValue:`${fecha_pago}`,
      showCancelButton: true,
      confirmButtonText: 'Pagar',
      cancelButtonText: 'cancelar',
      showLoaderOnConfirm: true,      
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {        
        Pagar({id_pagos,nombre,fecha:fecha_pago,id_lista_servicio:''});   
        toas('creando pago','warning');
      }
    })
  }
  const columnas = [
    {
      title: "Gasto",
      field: "nombre",
    },{
      title: "Dia de pago",
      field: "dia_pago",
    },{
      title: "Hoy",
      field: "hoy",
    },
    {
      title:'Fecha para pagar',
      field:'fecha_pago'
    },{
      title:'Estado',
      field:'estado'
    }
  ];
  /**
   * ,{
      title:'dias faltantes',
      field:'periodo'
    }
   */
  
  return (
    <>            
      <MaterialTable
        localization={{
          pagination: {
            labelDisplayedRows: "{from} - {to} total {count}",
            labelRowsSelect: "Filas",
            labelRowsPerPage: "Filas por página:",
            firstAriaLabel: "Primera página",
            firstTooltip: "Primera página",
            previousAriaLabel: "Pagina anterior",
            previousTooltip: "Pagina anterior",
            nextAriaLabel: "Siguiente página",
            nextTooltip: "Siguiente página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
          },
          toolbar: {
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
          },
        }}
        title="Validar Pagos"
        columns={columnas}
        data={Listado}
        components={{
          Action: props => (
            <Button
              onClick={(event) => props.action.onClick(event, props.data)}
              color="primary"
              variant="contained"                          
              className='btn btn-primary'
            >
              Pagar
            </Button>
          ),
        }}
        actions={[
          {
            icon: 'save',
            tooltip: 'Save User',
            onClick: (event, rowData) => {              
              Guardar(rowData);
            }
          }
        ]}
        options={{
          filtering: true,
          actionsColumnIndex:-1,                            
          exportButton: true
        }}                
      />
    </>
  )
}
