import React from 'react';

export const HeadTableFactura = () => {
  return (
    <thead>
      <tr>
        <th className="text-center pl-0 font-weight-bold text-muted text-uppercase text-secondary">
          servicio
        </th>
        <th className="font-weight-bold text-muted text-uppercase text-secondary">
          demo fecha inicio
        </th>
        <th className="font-weight-bold text-muted text-uppercase text-secondary">
          demo fecha fin
        </th>
        <th className="pr-0 font-weight-bold text-muted text-uppercase text-secondary">
          Días envió factura
        </th>
        {/* <th className="pr-0 font-weight-bold text-muted text-uppercase text-secondary">
          facturacion fecha fin
        </th> */}
        <th className="pr-0 font-weight-bold text-muted text-uppercase text-secondary">
          descuento
        </th>
        <th className="pr-0 font-weight-bold text-muted text-uppercase text-secondary">
          Total
        </th>
        <th className="pr-0 font-weight-bold text-muted text-uppercase text-secondary">
          #
        </th>
      </tr>
    </thead>
  );
}
