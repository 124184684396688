import { useEffect, useState } from "react";
import { insertUsuario } from "./../helpers/insertUsuario";
import { getUsuarios } from "./../helpers/getUsuarios";
import { selectPrivilegio} from "./../helpers/selectPrivilegio";

export const useFetcUsuarios = () => {
  const [dataPrivilegios, setdataPrivilegios] = useState({});
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [respUsuario, setrespUsuario] = useState('');
  
  useEffect(() => {
    traerPrivilegios();
    traerUsiarios();
  },[])
  
  const traerPrivilegios=()=>{
    selectPrivilegio()
    .then(resp=>{
      setdataPrivilegios(resp);
    });
  }

  const crearUsuario=(usuario)=>{
    insertUsuario(usuario)
    .then(res=>{
      setrespUsuario(res);
      traerUsiarios();      
    })
  }

  const traerUsiarios=()=>{
    getUsuarios()
    .then(rest=>{
      setListaUsuarios(rest);
    })
  }

  return {
    dataPrivilegios,
    respUsuario,
    crearUsuario,listaUsuarios
  }
}
