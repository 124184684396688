import React, { useEffect,useState } from 'react';
import moment from 'moment';
export const Tarjeta = ({titulo,cliente,fecha_inicio_demo,fecha_fin_demo,dia_factura}) => {
  
  const [demo, setDemo] = useState(0);  
  useEffect(() => {
    // calculamos totales de la demo
    let initF=moment();
    let finF=moment(fecha_fin_demo);
    let dias=finF.diff(initF,'days');
    setDemo(dias);    

    // eslint-disable-next-line
  }, []);


  const template = (
    <div className="col-12 col-sm-12 col-md-6 col-xl-4">
      <div
        className="card card-custom bgi-no-repeat card-stretch gutter-b"
        style={{
          backgroundPosition: "right",
          backgroundSize: "80% auto",
          backgroundImage:"url(https://humanly-sw.com/imagenologia/uploads/assets/isotipo_humanly-08.svg)",
        }}
      >
        <div
          className="card-body"
          style={{ background: "rgba(255,255,255,.60)" }}
        >
          <p className="card-title font-weight-bold  font-size-h5">          
            <h3>Servicio:{titulo}</h3>            
          </p>
          <div className="font-weight-bold text-primary mt-9 mb-5">
            <h3>Cliente: {cliente}</h3>
          </div>
          <p className="text-danger font-weight-bolder font-size-h5 m-0">
            {
              demo >0 &&demo<=10&&
              `Cuenta demo esta a ${demo} días de terminar`
            }
            
            {
              demo ===0 &&
              `Cuenta demo vencida`
            }

            {demo>10&&
              `En periodo de demostración`
            }

          </p>
        </div>
      </div>
    </div>
  );
  
  return (
    
    <>
      { demo>=0&& template}      
    </>
    
  );
}
