import React, { useEffect, useState } from "react";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import { useFetchFactura } from "../../hooks/useFetchFactura";
import { useFetchGastos } from "../../hooks/useFetchGastos";
import { useFetchHistorial } from "../../hooks/useFetchHistorial";
import { useFetchHistorialPago } from "../../hooks/useFetchHistorialPago";
import { Foother } from "../Foother";
import { HeadG } from "../HeadG";
import { HeadMovil } from "../HeadMovil";
import { TablaClientes } from "../Tablas/TablaClientes";
import { TablaSubscripciones } from "../Tablas/TablaSubscripciones";
import { Listas } from "../UI/Listas";
//import { Table } from "./../Table";
import { useFetchCliente } from "./../../hooks/useFetchCliente";
import { TableHistorial } from "./../Tablas/TableHistorial";
import { TarjetaMini } from "../Tarjeta/TarjetaMini";
import { useFetchNotificacion } from "../../hooks/useFetchNotificacion";

export const PagoFactura = () => {
  const [ListaProductos, setListaProductos] = useState([]);
  const [id_cliente, setId_cliente] = useState(null);
  const { productos, setCliente } = useFetchFactura();
  const { state } = useFetchCliente();
  const {
    ListaGastosVerificados,
    traerGastosVerificados,
    Activar,
    StatusActivar,
  } = useFetchGastos();
  const { ListadoHistorial, traerHistorial } = useFetchHistorialPago();
  const { toas } = useFetchNotificacion();
  const [premium1, setPremium1] = useState([]);
  const [vencidas, setVencidas] = useState([]);
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (privilegio === undefined) {
      window.location.href = "https://recursos.humanly-sw.com";
    }
  });

  useEffect(() => {
    let ArrayModificado = [];
    productos.map(
      ({
        costo,
        demo_fin,
        demo_ini,
        descuento,
        dia_factura,
        id,
        id_lista_servicios,
        id_vencimiento,
        nombre,
        premium,
        total,
      }) => {
        total = costo - (costo * descuento) / 100;
        ArrayModificado.push({
          costo,
          demo_fin,
          demo_ini,
          descuento,
          dia_factura,
          id,
          id_lista_servicios,
          id_vencimiento,
          nombre,
          premium,
          total,
        });

        return 0;
      }
    );
    setListaProductos(ArrayModificado);
  }, [productos]);

  useEffect(() => {
    const { msj, code } = StatusActivar;
    if (msj === "success" && code === 200) {
      toas("Servició activado");
    }
    // eslint-disable-next-line
  }, [StatusActivar]);

  useEffect(() => {
    valida_vigencias();
    // eslint-disable-next-line
  }, [productos]);

  const { get_productos, ContenidoServicio } = useFetchHistorial();
  /*
  const columns = [
    {
      name: "Cliente",
      selector: "cliente",
      sortable: true,
    },
    {
      name: "Servicio",
      selector: "servicio",
      sortable: true,
    },
    {
      name: "Fecha en la que se borro",
      selector: "created",
      sortable: true,
    },
  ];*/
  const { privilegio } = reactLocalStorage.getObject("session");

  const ActualizaPagos = () => {
    traerHistorial(id_cliente);
  };

  const valida_vigencias = () => {
    //console.table(productos);
    let hoy = moment().format("yyyy-MM-DD");
    let premiumA = [];
    let noPremiumA = [];
    productos.map(({ demo_fin, nombre, premium, id_lista_servicios }) => {
      console.log({ demo_fin, nombre, premium, id_lista_servicios });
      let fecha1 = moment(demo_fin);
      let fecha2 = moment(hoy);
      let dias = fecha1.diff(fecha2, "days");

      if (premium === "0") {
        if (dias < 0) {
          noPremiumA.push({ demo_fin, nombre, premium, id_lista_servicios });
        }
      } else {
        if (premium === "1") {
          premiumA.push({ demo_fin, nombre, premium, id_lista_servicios });
        }
      }
      return true;
    });
    setVencidas(noPremiumA);
    setPremium1(premiumA);
  };

  const ver_productos = (id) => {
    get_productos(id);
    setId_cliente(id);
    setCliente(id);
    traerHistorial(id);
  };

  const StatusPago = () => {
    traerGastosVerificados();
  };

  const ActivarServicio = (id) => {
    Activar(id);
    //GetFactura(id_cliente);

    let actualiza = [];
    let Activos = [];
    vencidas.map(({ demo_fin, nombre, premium, id_lista_servicios }) => {
      if (id_lista_servicios !== id) {
        actualiza.push({ demo_fin, nombre, premium, id_lista_servicios });
      } else {
        if (id_lista_servicios === id) {
          let existe = premium1.find(
            (servise) => servise.id_lista_servicios === id_lista_servicios
          );
          if (existe === undefined) {
            Activos.push({ demo_fin, nombre, premium, id_lista_servicios });
          }
        }
      }
      return true;
    });
    let copia = premium1;
    Activos.map((data) => {
      copia.push(data);
      return true;
    });
    setVencidas(actualiza);
    setPremium1(copia);
    setActive(!active);
  };

  return (
    <>
      <HeadMovil />
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <HeadG />
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="container col-12">
                <div className="row">
                  {vencidas.map(({ demo_fin, nombre, id_lista_servicios }) => {
                    return (
                      <TarjetaMini
                        demo_fin={demo_fin}
                        nombre={nombre}
                        evento={() => ActivarServicio(id_lista_servicios)}
                        key={id_lista_servicios}
                      />
                    );
                  })}
                </div>
                <div className="row">
                  <div className="card card-custom col-12 col-md-3 mr-2 ml-2  mt-5 left p-5">
                    <TablaClientes lista={state.data} evento={ver_productos} />
                  </div>

                  <div className="col-12 col-md-8 mr-2 ml-2  mt-5 right ">
                    {premium1.length !== 0 && (
                      <>
                        <div className="card card-custom col-12 mr-2 ml-2 mt-1 p-5 animate__animated animate__slideInUp">
                          <TablaSubscripciones
                            cliente={id_cliente}
                            lista={ListaProductos}
                            pagos={ListaGastosVerificados}
                            estado={StatusPago}
                            activos={premium1}
                            NuevaActivacion={active}
                            handleActualizaHistial={ActualizaPagos}
                          />
                        </div>

                        <div className="card card-custom col-12 col-md-12 mt-5 mr-2 ml-2  mt-1 p-5">
                          <Listas items={ContenidoServicio} />
                        </div>

                        <div className="card card-custom col-12 col-md-12 mt-5 mr-2 ml-2 mt-1 p-5">
                          <TableHistorial lista={ListadoHistorial} />
                        </div>
                      </>
                    )}
                    <div className="card card-custom col-12 col-md-12 mt-5 mr-2 ml-2  mt-1 p-5">
                      <Listas items={ContenidoServicio} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />

            <Foother />
          </div>
        </div>
      </div>
    </>
  );
};
