import React,{useEffect} from "react";
import {
  Redirect
} from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import { useFetchLogin } from "../../hooks/useFetchLogin";
import { useFetchNotificacion } from "../../hooks/useFetchNotificacion";
import "./login-19cd7.css";
import humanlyIsitipo from './isotipo_humanly.svg'
export const Login = () => {  
  const {handleChange,ingresa,mensajeLogin}=useFetchLogin();
  const {toas}=useFetchNotificacion();
  const  {privilegio}=reactLocalStorage.getObject('session');
  useEffect(() => {    
    if (mensajeLogin==='ingreso') {      
    } else {
      if (mensajeLogin!=='.'){
        toas(mensajeLogin,'error');
      }
    }
    // eslint-disable-next-line
  }, [mensajeLogin]);

  return (
    <>
     {mensajeLogin==='ingreso'&&<Redirect to='./home'/>} 
     {privilegio===undefined?'':<Redirect to='./home'/>}
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
          <div className="login-aside d-flex flex-column flex-row-auto" style={{ backgroundColor: "RGBA(254,118,51,.4)" }}>
            <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
              <img
                src={humanlyIsitipo}
                className="max-h-70px"
                alt="logo"
              />
              <h3
                className="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
                style={{ color: "#986923" }}
              >
                Humanly-sw 
              </h3>
            </div>
            <div
              className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
              style={{
                backgroundImage:
                  "url(https://preview.keenthemes.com/metronic/theme/html/demo2/dist/assets/media/svg/illustrations/login-visual-1.svg)",
              }}
            ></div>
          </div>
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            <div className="d-flex flex-column-fluid flex-center">
              <div className="login-form login-signin">
                <div
                  className="form"
                  noValidate="novalidate"
                  id="kt_login_signin_form"
                >
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Inicio se session 
                    </h3>
                  </div>
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      usuario
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                      type="text"
                      name="user"
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between mt-n5">
                      <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                        Contraseña
                      </label>
                    </div>
                    <input
                      className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="pb-lg-0 pb-5">
                    <button
                      type="button"
                      id="kt_login_signin_submit"
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                      onClick={ingresa}
                    >
                      Ingresar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
              <div className="text-dark-50 font-size-lg font-weight-bolder mr-10">
                <span className="mr-1">2020©</span>
                <a
                  href="https://humanly-sw.com/"
                  className="text-dark-75 text-hover-primary"
                >
                  humanly-sw
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
