export const ingresar=async({user,password})=>{
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  let urlencoded = new URLSearchParams();
  urlencoded.append("user",user);
  urlencoded.append("password",password);

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
  const url=`https://humanly-sw.com/api-administrar-sistemas/Login`;
  const resp = await fetch(url, requestOptions);
  const json = await resp.json();
  return json;
}