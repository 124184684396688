import React,{useState,useEffect} from "react";
import { reactLocalStorage } from 'reactjs-localstorage';
import { useFetcUsuarios } from "../hooks/useFetcUsuarios";
import { useFetchNotificacion } from "../hooks/useFetchNotificacion";
import { Foother } from "./Foother";
import { HeadG } from "./HeadG";
import { HeadMovil } from "./HeadMovil";
import { SelectPrivilegios } from "./SelectPrivilegios";
import { Table } from "./Table";

export const Usuarios = () => {
  const  {privilegio}=reactLocalStorage.getObject('session');
  const [usuario , setUsuario] = useState({usser:'',password:'',privilegio:0});  
  const {crearUsuario,respUsuario,listaUsuarios} =useFetcUsuarios();
  const {toas}=useFetchNotificacion();
  useEffect(() => {
    if (privilegio===undefined) {
      window.location.href='https://recursos.humanly-sw.com';  
    }        
  });
  useEffect(() => {        
    let {msj,code}=respUsuario;
    if (msj==='success') {
      toas('usuario creado correctamente');      
    }else{
      if(msj!==''&&code==='400'){        
        toas(`${msj}`,'error');
      }else{
        if(msj!==''&&msj!==undefined){
          toas(`${msj}`,'error');
        }
      }
    }
    // eslint-disable-next-line
  }, [respUsuario]);

  const handleChange=({target})=> {
    let {name,value}=target;    
    setUsuario({
        ...usuario,
        [name]:value
      }
    );
  }

  const onChangePrivilegio = ({ value}) => {
    setUsuario({
        ...usuario,
        privilegio:value
      }
    );    
  }

  const SaveUsuario=(e)=>{
    e.preventDefault();
    toas('Creando usuario','warning');
    crearUsuario(usuario)
  }

  const columns=[
      {
        name: 'Usuario',
        selector: 'ussername',
        sortable: true,
      },{
        name: 'Privilegio',
        selector: 'privilegio',
        sortable: true,
        right: true,
      }
    ];

  return (
    <>      
      <HeadMovil />
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <HeadG />
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="container">
                <div className="row">
                  {/* dentro de este div va montado todo */}
                  <div className="card card-custom col-12 col-md-6 mt-5">
                    <div className="card-header">                      
                      <h3 className="card-title">Registro de clientes</h3>
                    </div>
                    <form className="form" onSubmit={SaveUsuario}>
                      <div className="card-body">
                        <div className="form-group">
                          <label>
                            usuario
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" 
                            name="usser" 
                            onChange={handleChange} 
                            class="form-control" 
                            value={usuario.usser}
                            autoComplete="off"
                            placeholder="Escribir"/>
                        </div>
                        <div className="form-group">
                          <label>Contraseña<span className="text-danger">*</span></label>
                          <input type="text" 
                            name="password" 
                            value={usuario.password}
                            onChange={handleChange} 
                            class="form-control" 
                            placeholder="Escribir"
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-group">                          
                          <SelectPrivilegios
                            evento={onChangePrivilegio}
                          />
                        </div>
                      </div>
                      <div className="card-footer">
                        <button type="submit" className="btn btn-primary mr-2">
                          Guardar Usuario
                          <img src="https://img.icons8.com/fluent/30/000000/checked-user-male.png" alt="icono de usuario"/>
                        </button>
                      </div>
                    </form>
                  </div>
                  
                  <div className="card card-custom col-12 col-md-5 mt-5 ml-10">                    
                    <Table 
                      data={listaUsuarios} 
                      columns={columns} 
                      titulo='Lista de producto o servicio' 
                    />
                  </div>

                  {/* fin de todo el body del template */}
                </div>
              </div>
            </div>
            <Foother />
          </div>
        </div>
      </div>
    </>
  );
};
