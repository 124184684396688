export const updateGasto = async ({id_pagos,nombre,dia_pago}) => {
  try {
    console.log({id_pagos,nombre,dia_pago},'------------------');
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id_pagos", id_pagos);
    urlencoded.append("nombre", nombre);
    urlencoded.append("dia_pago", dia_pago);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    let url="https://humanly-sw.com/api-administrar-sistemas/Data/ActualizaGasto";
    const resp = await fetch(url, requestOptions);
    const json = await resp.json();
    return json;
  
  } catch (error) {
    return 0;
  }
}
  