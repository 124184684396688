import React,{useEffect} from 'react'; 
import {reactLocalStorage} from 'reactjs-localstorage';
import { HeadG } from './HeadG';
import { HeadMovil } from './HeadMovil';
import { Foother } from './Foother';
import { useFetchHistorial } from '../hooks/useFetchHistorial';
import { Tarjeta } from './Tarjeta';
import { Redirect } from 'react-router-dom';
export const Home = () => {
  const  {privilegio}=reactLocalStorage.getObject('session');
  const {listaGeneral} = useFetchHistorial();
  useEffect(() => {
    if (privilegio===undefined) {
      window.location.href='https://recursos.humanly-sw.com';  
    }        
  });
  return (
    <>      
      {privilegio===undefined&&<Redirect to='./'/>}
      <HeadMovil />
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <HeadG />
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="container col-12">
                <div className="row mt-10">                                    
                  {
                    listaGeneral.map(({producto,cliente,fecha_inicio_demo,fecha_fin_demo ,fecha_inicio_facturacion,fecha_fin_facturacion})=>{
                      return(
                        <Tarjeta
                          key={producto+cliente}
                          titulo={producto}
                          cliente={cliente}
                          fecha_inicio_demo={fecha_inicio_demo}
                          fecha_fin_demo={fecha_fin_demo}
                          fecha_inicio_facturacion={fecha_inicio_facturacion}
                          fecha_fin_facturacion={fecha_fin_facturacion}
                        />
                      )
                    })
                  }
                  {/* fin de todo el body del template */}
                </div>
              </div>
            </div>
            <Foother />
          </div>
        </div>
      </div>
    </>
  );
}
