export const EliminaProducto = async (id_producto) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  let urlencoded = new URLSearchParams();
  urlencoded.append("id_producto", id_producto);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  const url = `https://humanly-sw.com/api-administrar-sistemas/Data/EliminaServicioCatalogo`;
  const resp = await fetch(url, requestOptions);
  const json = await resp.json();
  return json;
};
