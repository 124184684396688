export const deleteProducto=async(id)=>{
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("id", id);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
      
  const url=`https://humanly-sw.com/api-administrar-sistemas/Data/Elimina_servicio`;  
  const resp=await fetch(url,requestOptions);
  const json=await resp.json();
  return json;
}