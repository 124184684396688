

export const selectCliente=async()=>{
  const url='https://humanly-sw.com/api-administrar-sistemas/Select/Get_combo_Clientes';
  const resp=await fetch(url);
  const json=await resp.json();
  return json;
}


