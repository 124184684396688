import React,{useEffect} from "react";
import { reactLocalStorage} from 'reactjs-localstorage';
import { Foother } from "./../Foother";
import { HeadG } from "./../HeadG";
import { HeadMovil } from "./../HeadMovil";

import imagen404 from './page-not-found.png';


export const Error404 = () => {
  const  {privilegio}=reactLocalStorage.getObject('session');
  useEffect(() => {
    if (privilegio===undefined) {
      window.location.href='https://recursos.humanly-sw.com';  
    }        
  });
  return (
    <>
      <HeadMovil />
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <HeadG />
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="container col-11">                
                <img src={imagen404} alt="imagen de error 404" style={{width:'100%'}} />                
              </div>
            </div>
            <Foother />
          </div>
        </div>
      </div>
    </>
  );
};
