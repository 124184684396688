import React, { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { HeadMovil } from "./../components/HeadMovil";
import { HeadG } from "./../components/HeadG";
import { Foother } from "./../components/Foother";

//import { Table } from "./Table";
import { useFetchProducto } from "../hooks/useFetchProducto";
import { useFetchNotificacion } from "./../hooks/useFetchNotificacion";
import { TablaServicios } from "./Tablas/TablaServicios";
//import { TablePagos } from './Tablas/TablePagos';

export const Servicios = () => {
  const { privilegio } = reactLocalStorage.getObject("session");
  const { toas } = useFetchNotificacion();
  const [producto, setproducto] = useState({ nombre: "", precio: 0 });
  const {
    lista,
    Inserted_message,
    NuevoProducto,
    ActualizaProducto,
    statusUpdate,
    DropProducto,
  } = useFetchProducto();
  useEffect(() => {
    if (privilegio === undefined) {
      window.location.href = "https://recursos.humanly-sw.com";
    }
  });

  useEffect(() => {
    let { code, msj } = statusUpdate;
    if (code === 200 && msj === "success") {
      toas("Servicio modificado");
    }
    // eslint-disable-next-line
  }, [statusUpdate]);

  useEffect(() => {
    if (Inserted_message.code === 400) {
      toas(Inserted_message.msj, "error");
    } else {
      if (Inserted_message.code === 200 && Inserted_message.msj === "success") {
        toas("Servicio creado correctamente");
        setproducto({ nombre: "", precio: 0 });
      }
    }
    // eslint-disable-next-line
  }, [Inserted_message]);

  const Updated = (obj) => {
    //console.table(obj);
    ActualizaProducto(obj);
  };

  const deleted = (id) => {
    DropProducto(id);
  };

  const handleChange = ({ target }) => {
    let { name, value } = target;
    setproducto({
      ...producto,
      [name]: value,
    });
  };
  // eslint-disable-next-line
  const columns = [
    {
      name: "Nombre",
      selector: "nombre",
      sortable: true,
    },
    {
      name: "Precio",
      selector: "precio",
      sortable: true,
    },
  ];
  const nuevo = (e) => {
    e.preventDefault();
    NuevoProducto(producto);
    toas("Creando producto", "warning");
  };

  return (
    <>
      <HeadMovil />
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <HeadG />
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="container">
                <div className="row">
                  {/* dentro de este div va montado todo */}
                  <div className="card card-custom col-12 col-md-6 mt-5">
                    <div className="card-header">
                      <h3 className="card-title">
                        Nombre del producto o servicio{" "}
                      </h3>
                    </div>
                    <form className="form" onSubmit={nuevo}>
                      <div className="card-body">
                        <div className="form-group">
                          <label>
                            Nombre :<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="nombre"
                            onChange={handleChange}
                            value={producto.nombre}
                            class="form-control"
                            placeholder="Escribir"
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Precio :<span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            name="precio"
                            value={producto.precio}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Escribir Precio"
                          />
                        </div>
                      </div>
                      <div className="card-footer">
                        <button type="submit" className="btn btn-primary mr-2">
                          Guardar Servicio
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="card card-custom col-12 col-md-5 ml-2 mr-2 mt-5 p-5">
                    <TablaServicios
                      informacion={lista}
                      handleUpdated={Updated}
                      handleDeleted={deleted}
                    />

                    {/* <hr />

                    <Table
                      data={lista}
                      columns={columns}
                      titulo="Lista de producto o servicio"
                    /> */}
                  </div>
                  {/* fin de todo el body del template */}
                </div>
              </div>
            </div>
            <Foother />
          </div>
        </div>
      </div>
    </>
  );
};
