export const getHistorialpagos=async(id)=>{
  try {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    let urlencoded = new URLSearchParams();
    urlencoded.append("id_cliente", id);    

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    let url="https://humanly-sw.com/api-administrar-sistemas/Data/historilaPagos";
    const resp = await fetch(url, requestOptions);
    const json = await resp.json();
    return json;
  
  } catch (error) {
    return 0;
  }
}
