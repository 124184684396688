import React from 'react';
import { Link }from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
// import { ItemMultipleMenu } from './default/ItemMultipleMenu';

export const Menu = () => {
  const  {privilegio}=reactLocalStorage.getObject('session');
  return (
    <>
      <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">								
        <div id="kt_header_menu" className="header-menu header-menu-left header-menu-mobile header-menu-layout-default">									
          <ul className="menu-nav">   

            <li className="menu-item ">
              <Link  to='/cotizacion' className="menu-link  ml-1 mr-1 text-white bg-primary mt-2" >
                cotización              
              </Link>
            </li>

            <li className="menu-item ">
              <Link  to='/cotizacion/ver' className="menu-link  ml-1 mr-1 text-white bg-primary mt-2" >
                Visualización cotización              
              </Link>
            </li>

            <li className="menu-item ">
              <Link  to='/servicios' className="menu-link  ml-1 mr-1 text-white bg-primary mt-2" >
                Servicios              
              </Link>
            </li>
            <li className="menu-item ">
              <Link  to='/clientes' className="menu-link  ml-1 mr-1 text-white bg-primary mt-2" >
                Clientes              
              </Link>
            </li>
            <li className="menu-item ">
                  <Link  to='/admin' className="menu-link  ml-1 mr-1 text-white bg-primary mt-2" >
                    Administración          
                  </Link>
                </li>
            {privilegio==='root'?
              <>
                {/*
                  <li className="menu-item ">
                    <Link  to='/admin' className="menu-link  ml-1 mr-1 text-white bg-primary mt-2" >
                      Administración          
                    </Link>
                  </li>
                  */
                }
                <li className="menu-item ">
                  <Link  to='/usser' className="menu-link  ml-1 mr-1 text-white bg-primary mt-2" >
                    Usuarios          
                  </Link>
                </li>
                <li className="menu-item ">
                  {/* <ItemMultipleMenu/> */}
                  
                  <Link  to='/pagos' className="menu-link  ml-1 mr-1 text-white bg-primary mt-2" >
                    Pagos   
                  </Link>
                </li>
              </>:''
            }                                    
          </ul>									
        </div>								
      </div>							
    </>
  )
}