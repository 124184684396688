import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import moment from "moment";
import "moment/locale/es";
import { Button } from "@material-ui/core";
import { useFetchGastos } from "../../hooks/useFetchGastos";
import Swal from "sweetalert2";

import { useFetchNotificacion } from "../../hooks/useFetchNotificacion";
//import { useFetchFactura } from "../../hooks/useFetchFactura";

export const TablaSubscripciones = ({
  cliente,
  lista,
  estado,
  pagos,
  activos = [],
  NuevaActivacion,
  handleActualizaHistial,
}) => {
  moment.locale("es");
  const { Pagar, insertedPago } = useFetchGastos();
  const { toas } = useFetchNotificacion();
  // eslint-disable-next-line
  const [data, setData] = useState([{}]);
  const [Listado, setListado] = useState([{}]);
  const [PagosAtrasados, setPagosAtrasados] = useState([]);

  useEffect(() => {
    cargar();
    // eslint-disable-next-line
  }, [NuevaActivacion]);

  useEffect(() => {
    cargar();
    // eslint-disable-next-line
  }, [lista, pagos]);

  useEffect(() => {
    setPagosAtrasados([]);
  }, [cliente]);

  const cargar = () => {
    let List = [];
    lista.map(
      ({
        dia_factura: dia_pago,
        id_lista_servicios: id_pagos,
        nombre,
        demo_fin,
        total,
      }) => {
        let hoy = moment().format(`yyyy-MM-DD`);

        let busca = activos.find(
          (activo) =>
            activo.nombre === nombre && activo.id_lista_servicios === id_pagos
        );

        if (busca !== undefined) {
          CalculaDias(demo_fin, hoy, dia_pago, id_pagos, nombre, total);
        }

        return true;
      }
    );
    //setData(List);
    PagosAtrasados.map(
      ({ dia_pago, estado, fecha_pago, hoy, id_pagos, nombre, total }) => {
        let prueba = List.find(
          (pago1) =>
            `${pago1.id_pagos}` === `${id_pagos}` &&
            `${pago1.fecha_pago}` === `${fecha_pago}`
        );

        //esto validad si el pago ya fue realizado
        let existe = pagos.find(
          (pago) =>
            `${pago.id_lista_servicio}` === `${id_pagos}` &&
            `${pago.fecha}` === `${fecha_pago}`
        );

        //console.log(existe);
        if (existe === undefined) {
          if (prueba === undefined) {
            //if (EstaActivo(id_pagos,nombre)) {
            List.push({
              dia_pago,
              estado,
              fecha_pago,
              hoy,
              id_pagos,
              nombre,
              total,
            });
            //}
          }
        }
        return true;
      }
    );
    setListado(List);
  };

  useEffect(() => {
    const { code, msj } = insertedPago;
    if (code === 200 && msj === "success") {
      toas("Pago creado correctamente");
      estado();
    } else {
      if (code === 400) {
        toas(msj, "warning");
      }
    }
    // eslint-disable-next-line
  }, [insertedPago]);

  //fecha_pago
  const Guardar = ({ id_pagos, nombre, fecha_pago }) => {
    Swal.fire({
      title: `Gasto de ${nombre} a pagar`,
      input: "text",
      inputAttributes: {
        disabled: true,
      },
      inputValue: `${fecha_pago}`,
      showCancelButton: true,
      confirmButtonText: "Pagar",
      cancelButtonText: "cancelar",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Pagar({
          id_pagos: "",
          nombre,
          fecha: fecha_pago,
          id_lista_servicio: id_pagos,
        });
        toas("creando pago", "warning");
        setPagosAtrasados([]);
        handleActualizaHistial();
      }
    });
  };

  const CalculaDias = (
    FinDemo = "",
    fecha_fin,
    dias_vencimiento,
    id_pagos,
    nombre,
    total
  ) => {
    id_pagos = parseInt(id_pagos);
    let fecha1 = moment(FinDemo);
    let fecha2 = moment(fecha_fin);
    let meses = fecha2.diff(fecha1, "months");

    let hoy = moment().format(`yyyy-MM-DD`);

    let pagoVerificado = [];
    pagos.map(({ fecha, id_lista_servicio, id_pagos_verificados }) => {
      id_lista_servicio = parseInt(id_lista_servicio);
      if (id_pagos === id_lista_servicio) {
        pagoVerificado.push({
          fecha,
          id_lista_servicio,
          id_pagos_verificados,
        });
      }
      return true;
    });

    let [years, mes, dia] = FinDemo.split("-");
    mes = parseInt(mes);
    dia = parseInt(dia);
    years = parseInt(years);

    if (dia > 5) {
      mes += 1;
    }

    if (mes > 12) {
      mes = 1;
      years += 1;
    }
    let coleccion = [];

    if (meses === 0) {
      let fecha_pago = moment().format(
        `[${years}]-[${mes}]-[${dias_vencimiento}]`
      );
      fecha_pago = formatoFecha(fecha_pago);
      //if (EstaActivo(id_pagos,nombre)) {
      coleccion.push({
        fecha_pago,
        dia_pago: dias_vencimiento,
        id_pagos,
        nombre,
        estado: "Pago pendiente",
        hoy,
        total,
      });
      //}
    } else {
      for (let i = 1; i <= meses + 1; i++) {
        let fecha_pago = moment().format(
          `[${years}]-[${mes}]-[${dias_vencimiento}]`
        );
        let mesActual = moment().format("MM");
        let yearActual = moment().format("yyy");
        mesActual = parseInt(mesActual);
        yearActual = parseInt(yearActual);

        //esto valida que no se pases del mes actual en el calculo de las fechas
        if (mes <= mesActual || years < yearActual) {
          fecha_pago = formatoFecha(fecha_pago);
          //if (EstaActivo(id_pagos,nombre)) {
          coleccion.push({
            fecha_pago,
            dia_pago: dias_vencimiento,
            id_pagos,
            nombre,
            estado: "Pago pendiente",
            hoy,
            total,
          });
          mes++;
          if (mes > 12) {
            mes = 1;
            years += 1;
          }
          //}
        }
      }
    }
    let Acumulador = PagosAtrasados;
    coleccion.map((datos) => {
      Acumulador.push(datos);
      return true;
    });
    setPagosAtrasados(Acumulador);
  };
  const columnas = [
    {
      title: "Gasto",
      field: "nombre",
    },
    {
      title: "Dia de pago",
      field: "dia_pago",
    },
    {
      title: "Hoy",
      field: "hoy",
    },
    {
      title: "Monto a cobrar",
      field: "total",
    },
    {
      title: "Fecha para pagar",
      field: "fecha_pago",
    },
    {
      title: "Estado",
      field: "estado",
    },
  ];

  const formatoFecha = (fecha) => {
    let [years, mes, dia] = fecha.split("-");
    if (mes.length === 1) {
      mes = `0${mes}`;
    }
    if (dia.length === 1) {
      dia = `0${dia}`;
    }
    return `${years}-${mes}-${dia}`;
  };

  return (
    <>
      <MaterialTable
        localization={{
          pagination: {
            labelDisplayedRows: "{from} - {to} total {count}",
            labelRowsSelect: "Filas",
            labelRowsPerPage: "Filas por página:",
            firstAriaLabel: "Primera página",
            firstTooltip: "Primera página",
            previousAriaLabel: "Pagina anterior",
            previousTooltip: "Pagina anterior",
            nextAriaLabel: "Siguiente página",
            nextTooltip: "Siguiente página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
          },
          toolbar: {
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
          },
        }}
        title="Validar Pagos"
        columns={columnas}
        data={Listado}
        components={{
          Action: (props) => (
            <Button
              onClick={(event) => props.action.onClick(event, props.data)}
              color="primary"
              variant="contained"
              className="btn btn-primary"
            >
              Pagar
            </Button>
          ),
        }}
        actions={[
          {
            icon: "save",
            tooltip: "Save User",
            onClick: (event, rowData) => {
              Guardar(rowData);
              /*console.log(rowData)*/
            },
          },
        ]}
        options={{
          filtering: true,
          actionsColumnIndex: -1,
          exportButton: true,
        }}
      />
    </>
  );
};
