import { useState } from 'react';
import { getHistorialpagos } from '../helpers/getHistorialpagos';

export const useFetchHistorialPago = () => {
  const [ListadoHistorial, setListadoHistorial] = useState([]);

  const traerHistorial=(id)=>{
    getHistorialpagos(id)
    .then(respuesta=>{
      setListadoHistorial(respuesta)
    })
  }

  return {traerHistorial,ListadoHistorial}
}
