import React, { useState } from "react";
import Modal from "react-modal";
import { useFetchNotificacion } from "./../hooks/useFetchNotificacion";
import moment from "moment";
import "moment/locale/es";
export const Modal2 = ({
  Pid,
  nombre,
  precio,
  habilitada,
  closeModal,
  Pid_cliente,
  guardar,
}) => {
  moment.locale("es");
  const [state, setState] = useState({
    id: Pid,
    costo: precio,
    demo_ini: new moment().format("YYYY-MM-DD"),
    demo_fin: new moment().format("YYYY-MM-DD"),
    descuento: 0,
    total: 0,
    dias: 1,
    cliente: {id:Pid_cliente },
  });
  const { toas } = useFetchNotificacion();

  const [Total, setTotal] = useState(0);

  const crearObjeto = ({ target }) => {
    let { name, value } = target;
    let {
      id,
      costo,
      demo_ini,
      demo_fin,
      descuento,
      total,
      cliente: { id:cliente_id },
      dias,
    } = state;
    switch (name) {
      case "demo_ini":
        demo_ini = value;
        break;
      case "demo_fin":
        demo_fin = value;
        break;
      case "meses":
        let mm = moment(demo_ini, "yyyy-MM-DD");
        mm.add(value, "M").format("LLL");
        let fechaFinal = mm.toString();
        demo_fin = moment(fechaFinal).format("yyyy-MM-DD");
        break;
      case "descuento":
        if (value >= 0 && value <= 100) {
          if (value === "") {
            value = 0;
          }
          let total2 = calculaTotal(precio, value);
          toas(`se aplico descuento de ${value}%`);
          setTotal(total2);
          total=total2;
          descuento = value;
        } else {
          toas("Error el rango de descuentos es entre 0 y 100", "error");
          descuento = 0;
          setTotal(0);
        }
        break;
      case "dias":
        dias = value;
        break;
      default:
        break;
    }
    let id_cliente = Pid_cliente;
    costo=precio;
    id=Pid;
    // eslint-disable-next-line
    cliente_id=Pid_cliente;
    setState({
      id,
      dias,
      costo,
      demo_ini,
      demo_fin,
      descuento,
      total,
      cliente: { id:id_cliente },
    });
  };

  const calculaTotal = (costo, descuento) => {
    let total = costo - (costo * descuento) / 100;
    return total;
  };

  const SaveServicio = (e) => {
    e.preventDefault();

    const { demo_ini, demo_fin} = state;
    
    if (validaDate(demo_ini) &&validaDate(demo_fin)) {
      guardar(state);
      setTotal(0);
    } else {
      toas("Error fechas no validas");
    }
    
  };

  const validaDate = (fecha) => {
    if (fecha !== "0000-00-00" && fecha !== "") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Modal
        isOpen={habilitada}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <button onClick={closeModal} className="btn btn-primary right">
          Cerrar
        </button>
        {/* {JSON.stringify(state)} */}
        <br /><br />
        <h3 className="text-center">Agregar {nombre}</h3>
        <br /><br />
        <form className="row" onSubmit={SaveServicio}>
          <div className="form-group col-6 col-md-6">
            <label>fecha de inició de la demo</label>
            <input
              type="date"
              name="demo_ini"
              value={state.demo_ini}
              onChange={crearObjeto}
              className="form-control"
            />
            <div className="form-group">
              <label>Meses de demo</label>
              <input
                type="number"
                className="form-control"
                name="meses"
                onChange={crearObjeto}
                placeholder="Escribir meses"
              />
            </div>
          </div>

          <div className="form-group col-6 col-md-6">
            <label>fecha de fin de la demo</label>
            <input
              type="date"
              onChange={crearObjeto}
              name="demo_fin"
              className="form-control "
              disabled={true}
              value={state.demo_fin}
            />
          </div>

          <div className="row col-12 ">
            <div className="form-group col-6">
              <label>descuento</label>
              <input
                type="number"
                value={state.descuento}
                onChange={crearObjeto}
                min={0}
                max={100}
                name="descuento"
                className="form-control "
              />
            </div>

            <div className="form-group col-6 col-md-6">
              <label>Dia en que se enviara la factura</label>
              <select
                value={state.dias}
                className="form-control "
                onChange={crearObjeto}
                name="dias"
              >
                {/* <option value='DEFAULT' disabled={true} >Seleccionar</option> */}
                <option value={1}>1° dia del mes</option>
                <option value={2}>2° dia del mes</option>
                <option value={3}>3° dia del mes</option>
                <option value={4}>4° dia del mes</option>
                <option value={5}>5° dia del mes</option>
              </select>
            </div>
          </div>
          <div className="form-group col-12">
            <label>Precio sin descuento</label>
            <input
              type="text"
              value={precio}
              name={`costo-${Pid}`}
              className="form-control"
              disabled
            />
          </div>
          <div className="col-12">
            <h3 className=" text-center text-danger">
              Total: {Total !== 0 ? Total : precio}
            </h3>
          </div>
          <br /><br />
          <button className="btn btn-primary" type="submit">
            Agregar servicio
          </button>
        </form>
      </Modal>
    </div>
  );
};
