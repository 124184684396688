import {useState} from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import {ingresar} from './../helpers/ingresar';
export const useFetchLogin = () => {
  const [state, setState] = useState({user:'',password:''});  
  const [mensajeLogin, setMensajeLogin] = useState('.');

  const ingresa=()=>{
    ingresar(state)
      .then(({data:{existe,ussername,privilegio}})=>{
        if (existe==='1') {          
          setMensajeLogin('ingreso');
          reactLocalStorage.clear();
          reactLocalStorage.setObject('session',{ussername,privilegio});     
          window.location.reload();
        }else{
          if (existe==='0') {
            setMensajeLogin('Usuario o contraseña incorrecta');            
          }
        }
      });    
  }

  const handleChange=({target})=> {
    let {name,value}=target;    
    setState({
      ...state,
      [name]:value
    });
  }

  return{
    handleChange,
    ingresa,
    mensajeLogin
  }
}


