import React from 'react';
import {Routers} from './Routers';
// import { Head } from './components/Head';

export const App=()=>{
  return(
    <>
      <Routers/>
    </>
  )
}
