import { useState, useEffect } from "react";
import axios from "axios";
import { getProductos } from "./../helpers/getProductos";
import { selectProducs } from "./../helpers/selectProducs";
import { deleteProducto } from "../helpers/deleteProducto";
import { modificaProducto } from "../helpers/Updates/modificaProducto";
import { EliminaProducto } from "../helpers/deleteds/EliminaProducto";
export const useFetchProducto = () => {
  const [lista, setlista] = useState({
    data: [],
    loading: true,
  });

  const [Inserted_message, setInserted_message] = useState({});
  //para llevar la coleccion que se le van a meter al select
  const [Opciones_producto, setOpciones_producto] = useState([]);
  //lleva el estado de lo eliminado
  const [Statedeleted, setStatedeleted] = useState({
    data: "",
    code: 200,
    msj: "",
  });

  const [statusUpdate, setStatusUpdate] = useState({ msj: "", code: 200 });
  const [statusDeleted, setStatusDeleted] = useState({ msj: "", code: 200 });

  useEffect(() => {
    getProductos().then((producto) => {
      setlista(producto.data);
    });

    selectProducs().then((select) => {
      setOpciones_producto(select);
    });
  }, []);

  const traerProductos = () => {
    getProductos().then((producto) => {
      setlista(producto.data);
    });
  };

  const NuevoProducto = (producto) => {
    axios
      .post(
        "https://humanly-sw.com/api-administrar-sistemas/Producto",
        producto
      )
      .then((respuesta) => {
        traerProductos();
        setInserted_message(respuesta.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const Borra_producto = (id) => {
    deleteProducto(id).then((estatus) => {
      setStatedeleted(estatus);
    });
  };

  const ActualizaProducto = (objProducto) => {
    modificaProducto(objProducto).then((respuesta) => {
      setStatusUpdate(respuesta);
      traerProductos();
    });
  };

  const DropProducto = (id) => {
    EliminaProducto(id).then((respuesta) => {
      setStatusDeleted(respuesta);
      traerProductos();
    });
  };

  return {
    lista,
    Inserted_message,
    NuevoProducto,
    Opciones_producto,
    Borra_producto,
    Statedeleted,
    ActualizaProducto,
    statusUpdate,
    DropProducto,
    statusDeleted,
  };
};
