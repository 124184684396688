import { useState,useEffect } from 'react';
import axios from 'axios';
import { getCientes } from './../helpers/getCientes';
import {selectCliente} from './../helpers/selectCliente';
export const useFetchCliente = () => {
  const [state, setstate] = useState({
    data: [],
    loading: true
  }); 
  const [Inserted_message, setInserted_message] = useState({});
  const [OptionsClientes, setOptionsClientes] = useState(null)

  useEffect(() => {
    getCientes()
      .then(clientes => {
        setstate(clientes);
      });
    selectCliente()
      .then(select=>{
        setOptionsClientes(select)
      })
  }, []);

  const traerClientes = () => {
    getCientes()
      .then(clientes => {
        setstate(clientes);
      })
  }

  const  NuevoCliente =  (cliente) => {        
    axios.post('https://humanly-sw.com/api-administrar-sistemas/Cliente',
      cliente,        
    )
    .then((respuesta)=>{     
      traerClientes();
      setInserted_message(respuesta.data);
    })
    .catch(err=>{
      console.log(err);
    })
  }

  return {
    state,
    Inserted_message,
    traerClientes,
    NuevoCliente,
    OptionsClientes
  };
}