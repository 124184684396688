export const insertUsuario=async({usser,password,privilegio})=>{

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("usser", usser);
  urlencoded.append("password",password );
  urlencoded.append("privilegio",privilegio);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
  const url ="https://humanly-sw.com/api-administrar-sistemas/Usuario";  
  const resp = await fetch(url, requestOptions);
  const json = await resp.json();
  return json;
}