import React from 'react';
import Select from "react-select";
import { useFetchProducto } from "./../hooks/useFetchProducto";


export const SelectProducto = ({habilita=false,evento}) => {
  const { Opciones_producto } = useFetchProducto();
  return (
    <div className="form-group col-12 col-md-6">
      <label className=" text-secondary">
        Seleccionar el producto o servicio
      </label>
      <Select
        options={Opciones_producto}
        onChange={evento}
        isDisabled={habilita}
        placeholder='Seleccionar'
      />
    </div>
  );
}
