
export const serviciosByClientes = async ($id_CLiente) => {
  try {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("id", $id_CLiente);

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };


    let url="https://humanly-sw.com/api-administrar-sistemas/Data/lista";
    const resp = await fetch(url, requestOptions);
    const json = await resp.json();
    return json;
  
  } catch (error) {
    return 0;
  }
}
    