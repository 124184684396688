import React from 'react';
import Select from "react-select";
import { useFetchCliente } from "../hooks/useFetchCliente";
export const SelectCliente = ({habilita=false,evento=null}) => {
  const { OptionsClientes } = useFetchCliente();
  return (
    <div className="form-group col-12 col-md-6">
      <label>Seleccionar cliente</label>
      <Select
        options={OptionsClientes}
        onChange={evento}
        isDisabled={habilita}
        placeholder='Seleccionar cliente'        
      />
    </div>
  );
}
