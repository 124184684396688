import React from 'react'

export const Foother = () => {
  return (    
    <div style={{width:'100%',position:'fixed',clear:'both',marginTop:'0px',overflow:'visible',top:'93%'}} className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer" >			
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">			
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">2020©</span>
          <a href="https://humanly-sw.com" className="text-dark-75 text-hover-primary">humanly-sw</a>
        </div>
      </div>
    </div>
  )
}

