import React from "react";
export const ItemFactura = ({
  dia_factura,
  nombre,
  id,
  costo,
  total,
  cancelDemo = false,
  descuento = 0,
  demo_ini = "",
  demo_fin = "",
  evento,
  elimina,
  segunda_cotisacion = false,
  descuentoEdita = true,
}) => {
  const formato=(datos=0)=>{
    let data=parseFloat(datos);
    return `$${data.toFixed(2)}`;
  }
  return (    
    <>
      <tr className="font-weight-boldest animate__animated animate__fadeInUp">
        <td className="pl-0 pt-7">{nombre}</td>
        <td className="text-right pt-7">
          <input
            type="date"
            onChange={evento}
            name={`fecha_ini_demo-${id}`}
            value={demo_ini}
            placeholder="Seleccionar fecha"
            className="form-control"
            disabled={cancelDemo}
          />

          <div className="form-group">
            <input
              type="number"
              name={`meses-${id}`}
              placeholder="Añadir numero de meses de la demo"
              className="form-control"
              onChange={evento}
              disabled={segunda_cotisacion}
            />
            <div id="passwordHelpBlock" className="form-text text-danger">
              * Escribir en numero de meses
            </div>
          </div>
        </td>
        <td className="text-right pt-7">
          <input
            type="date"
            onChange={evento}
            name={`fecha_fin_demo-${id}`}
            value={demo_fin}
            placeholder="Seleccionar fecha"
            className="form-control"
            disabled={true}
          />
        </td>
        <td className="text-right pt-7">
          <select
            value={dia_factura}
            className="form-control"
            onChange={evento}
            name={`dias-${id}`}
          >
            {/* <option value='DEFAULT' disabled={true} >Seleccionar</option> */}
            <option value={1}>1° dia del mes</option>
            <option value={2}>2° dia del mes</option>
            <option value={3}>3° dia del mes</option>
            <option value={4}>4° dia del mes</option>
            <option value={5}>5° dia del mes</option>
          </select>

          {/* <input
            type="date"
            onChange={evento}
            name={`fecha_ini_factura-${id}`}
            value={factura_ini}
            placeholder="Seleccionar fecha"
            className="form-control"
          /> */}
        </td>
        {/* <td className="text-right pt-7">
          <input
            type="date"
            onChange={evento}
            name={`fecha_fin_factura-${id}`}
            value={factura_fin}
            placeholder="Seleccionar fecha"
            className="form-control"
          />
        </td> */}
        <td className="text-right pt-7">
          <input
            min={-500}
            max={100}
            placeholder="escribir de 1 a 100"
            type="number"
            onChange={evento}
            name={`descuento-${id}`}
            value={descuento}
            className="form-control"
            disabled={descuentoEdita}
          />
        </td>
        <td className="text-danger pt-7 text-right">
          Precio con descuento: {/*total === 0 ? costo : total*/}
          {formato(total) }
          <br />
          Precio fijo:&nbsp;&nbsp;{costo}
        </td>
        <td>
          <div>
            <button className="btn" type="button" onClick={elimina}>
              <img
                src="https://img.icons8.com/ios-glyphs/30/000000/trash--v3.png"
                alt="clear"
              />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};
