import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { ListaItem } from './ListaItem';
//import { TablaSubscripciones } from '../Tablas/TablaSubscripciones';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export const Listas = ({items=[]}) => {
  const classes = useStyles();

  return (
    <>
      <div className="alert alert-custom alert-outline-primary fade show mb-5 mt-5" role="alert">
        <div className="alert-icon">
          <img src="https://img.icons8.com/cute-clipart/40/000000/info.png" alt='logotipo de listas'/>
        </div>
        
          <div className="alert-text">                    
            Lista de servicios del cliente: {items[0]?.cliente&&items[0].cliente}
          </div>
        
      </div>
      <List className={classes.root}>
        {items.map(({producto,fecha_inicio_demo,fecha_fin_demo,dia_factura})=>{
          if (producto!==''&&fecha_inicio_demo!=='' && fecha_fin_demo!=='') {
            return(
              <ListaItem
                key={producto}
                dia_factura={dia_factura}
                producto={producto}
                fecha_inicio_demo={fecha_inicio_demo}
                fecha_fin_demo={fecha_fin_demo}
              />
            )  
          }else{
            return(
              <p 
                key={producto}>
                Datos no encontrados
              </p>
            );
          }
          
        })}
        
      </List>
       
    </>
  );
}
