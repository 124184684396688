import React from 'react';
import Select from "react-select";
import { useFetcUsuarios } from "./../hooks/useFetcUsuarios";


export const SelectPrivilegios = ({habilita=false,evento}) => {
  const { dataPrivilegios } = useFetcUsuarios();
  return (
    <div className="form-group col-12">
      <label className=" text-secondary">
        Seleccionar Privilegio
      </label>
      <Select
        options={dataPrivilegios}
        onChange={evento}
        isDisabled={habilita}
        placeholder='Seleccionar privilegio'
      />
    </div>
  );
}
